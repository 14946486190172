import { handleErrorWithSentry } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://fc650f1cfb6101861ec6c97a754ac461@o4506379064770560.ingest.sentry.io/4506379092033536',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Ignore error from Google ReCaptcha that has no side effects
  beforeSend(event, hint) {
    if (hint.originalException === "Timeout") return null;
    return event;
  },
});

// Use customer compression worker to comply with CSP:
Sentry.replayIntegration({
  workerUrl: '/js/worker.min.js',
  networkDetailAllowUrls: [window.location.origin],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
