import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/agent": [~3],
		"/agent/entity/[[id]]/[[email]]": [~4],
		"/agent/mail/[guid]/[[magicEmail]]": [~5],
		"/agent/service/[guid]/[[magicEmail]]": [~6],
		"/authentication/account": [7],
		"/filing": [~8],
		"/filing/boir": [9],
		"/filing/create": [10],
		"/filing/edit/[[id]]": [~11],
		"/filing/principal/[[magicLink]]/[[magicEmail]]": [~12],
		"/filing/view/[[id]]/[[corporateTitle]]": [~13],
		"/invoice-payment": [~14],
		"/login": [15],
		"/policy/acceptable-use": [16],
		"/policy/cookie": [17],
		"/policy/privacy": [18],
		"/policy/terms-of-service": [19]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';